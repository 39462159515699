import React from "react"
import SEO from "../components/seo"
import { Header } from "../components/Header"
import { ProjectList } from "../components/ProjectList"
import { Footer } from "../components/Footer"

const Projects = () => {
  return (
    <section className="min-h-screen flex flex-col justify-between">
      <SEO title="Projects" />
      <Header />
      <ProjectList />
      <Footer />
    </section>
  )
}

export default Projects
